@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

.mainBackground {
  background-image: url("./Assets/mainBackground.png");
  background-position: right 0px;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
select {
  font-family: Poppins;
}

.skew {
  position: relative;
  width: 104px;
}

.skew:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skewRed {
  position: relative;
  width: 104px;
}

.skewRed:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #ec3323;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew2 {
  position: relative;
  width: 104px;
}

.skew2:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew2Red {
  position: relative;
  width: 104px;
}

.skew2Red:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #ec3323;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew3 {
  position: relative;
  width: 104px;
}

.skew3:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew3:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew3Red {
  position: relative;
  width: 104px;
}

.skew3Red:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #ec3323;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew3Red:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #ec3323;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew4 {
  position: relative;
  width: 104px;
}

.skew4:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew4:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew5 {
  position: relative;
  width: 104px;
}

.skew5:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew5:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew6 {
  position: relative;
  width: 104px;
}

.skew6:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: bottom left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.skew6:after {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d9d9d9;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.blue_txt {
  color: #273a89;
}

.bskew6 {
  flex: 1;
  position: relative;
}

.bskew6:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: #ff9f43;
}

.bskew7 {
  flex: 1;
  position: relative;
}

.bskew7:before {
  content: " ";
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: #273a89;
  transform-origin: top left;
  -ms-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

.boxShadow {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.childseat-wrapper {
  margin-top: 15px;
}
.childseat-wrapper select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0 10px;
  box-shadow: 0 0 2px 2px #273a893d;
}
@media (max-width: 1366px) {
  .quote__wrapper {
    margin-right: 40px;
  }
  #ho {
    padding: 0 20px;
  }

  .footer-wrapper h2 {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .web-menu img {
    width: 180px;
  }
  .web-menu button {
    font-size: 15px;
    width: auto;
    padding: 0 15px;
  }
  .web-menu ul li {
    font-size: 12px;
    color: #c1c1c1;
  }
  #contact h2 {
    line-height: 20px;
  }
  #contact h3 {
    margin-top: 0;
    line-height: 16px;
  }
  .slick-slider p {
    min-width: 100%;
  }
  .footer-wrapper h2 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .mob-menu img {
    width: 150px;
  }
  .footer-wrapper h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .footer__menus {
    display: block;
  }
  .top-gap {
    margin-top: 40px;
  }
  .footer-wrapper .call__wrapper {
    margin-bottom: 0px;
  }
  .contact__page .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media (max-width: 640px) {
  .footer-wrapper {
    padding-top: 40px;
  }
  .footer-wrapper .call__wrapper {
    margin-bottom: 0px;
  }
  .xsm\:flex-col-reverse {
    align-items: flex-start;
  }
  .footer__menus > ul {
    width: 100%;
  }
  #benefit {
    width: 90%;
    margin: 0 auto;
  }
  .list-disc {
    column-count: 1 !important;
  }
}

@media only screen and (max-width: 767px) {
  .skew {
    width: 59.12px;
  }

  .skew2 {
    width: 59.12px;
  }

  .skew3 {
    width: 59.12px;
  }

  .skew4 {
    width: 59.12px;
  }

  .skew5 {
    width: 59.12px;
  }

  .skew6 {
    width: 59.12px;
  }
}
